<template>
  <div class="wrap">
    <div>
      <img src="../../assets/activity/home-7.jpg" v-if="lang==='ZH'" class="top-img" />
      <img src="../../assets/activity/home-6.jpg" v-else class="top-img" />
    </div>
    <div class="title-box">
      <div class="marketing">{{ ecology.marketing }}</div>
      <div class="Genesis">———{{ ecology.Genesis }}</div>
    </div>
    <div class="donate-box">
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.total }}</div>
        <div class="money">
          {{ this.$t("ecology.individual", {total:(donatedInfo.donatedCount * donatedInfo.amount)/100000 || 0} ) }}
        </div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.purpose }}</div>
        <div class="money">{{ ecology.mark }}</div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.Number }}</div>
        <div class="money">
          {{ this.$t("ecology.Donation", { nunber: (donatedInfo.amount)/100000 || 0 }) }}
        </div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.equity }}</div>
        <div class="money">{{ ecology.dividend }}</div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.Current }}</div>
        <div class="money">
          {{ this.$t("ecology.have", { value: donatedInfo.donatedCount || 0 })
          }}{{
            this.$t("ecology.remaining", {
              value: donatedInfo.donateLimit - donatedInfo.donatedCount || 0,
            })
          }}
        </div>
      </div>
      <div class="yinxiao-box">
        <div class="edu">{{ ecology.quantity }}</div>
        <div class="money">{{this.$t("ecology.individual", {total:(donatedInfo.totalCount)/100000 || 0} ) }}</div>
      </div>
      <div class="adress-box">
        <div class="edu">{{ ecology.address }}</div>
        <div class="address" @click="handleCopy(lightingAddress)">
          <div class="title">{{ $gbUtils.formatAddress(lightingAddress) }}</div>
          <img src="@/assets/copy.png" class="copy-img" />
        </div>
      </div>
    </div>
    <van-button
        class="btn-div"
        @click="approveLP"
        :loading="approveLoading"
        :disabled="donatedInfo.donateLimit === donatedInfo.donatedCount || flag"
        v-if="!authLp"
        >{{ecology.grant}}</van-button
      >
      <van-button
        class="btn-div"
        v-else
        :loading="loadingApprove"
        @click="approveDonation"
        :disabled="(donatedInfo.donateLimit === donatedInfo.donatedCount || flag)"
        >{{ ecology.now }}</van-button
      >
      <van-button
        class="btn-div"
        @click="go('/donateDividends')"
        >{{ ecology.fen }}</van-button
      >
  </div>
</template>

<script>
import { mapState } from 'vuex'
import donation from '@/utils/donation'
import contractToken from '@/contracts/contractToken'
import config from '@/config/app.config.js' // 引入配置文件
export default {
  name: 'propaganda',
  components: { },
  data () {
    return {
      donatedInfo: {},
      lightingAddress: config.donationAddress,
      eggShowTotal: false,
      shade: false,
      loadingApprove: false,
      authLp: false,
      approveLoading: false,
      lpAllowance: 0,
      flag: false,
      lang: 'ZH'
    }
  },
  computed: {
    ...mapState(['mbLang']),
    propaganda () {
      return this.$t('ecology.propaganda')
    },
    ecology () {
      return this.$t('ecology')
    }
  },
  methods: {
    init () {
      this.handledonationInfo()
      this.handleIsable()
    },
    // 路由跳转
    go (url) {
      this.$gbUtils.handleToPage(url)
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        // this.lpAddress,
        this.donatedInfo.token,
        this.lightingAddress // 合约地址
      )
      console.log(resp, 'dede')
      if (resp.success) {
        this.lpAllowance = resp.result
        this.authLp = this.lpAllowance > 0
      }
      this.approveLoading = false
    },
    // 授权LP
    async approveLP () {
      this.approveLoading = true
      const resp = await contractToken.approve(
        // this.lpAddress,
        this.donatedInfo.token,
        this.lightingAddress // 合约地址
      ) // 授权LP
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.authLp = true
    },
    // 参与捐赠
    async approveDonation () {
      console.log(212)
      this.loadingApprove = true
      console.log(this.donatedInfo.amount)
      const resp = await donation.donatedNow(
        this.donatedInfo.token,
        this.donatedInfo.amount
      )
      console.log(resp, 'dge')
      this.loadingApprove = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('success')
      this.init()
    },
    // 复制
    handleCopy (content) {
      const text = document.createElement('textarea')
      text.value = content
      document.body.appendChild(text)
      text.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$toast('copied')
      }
      // 执行浏览器复制命令
      document.body.removeChild(text)
    },
    handleToPage (link) {
      if (link) {
        window.open(link)
      } else {
        return false
      }
    },
    handleShowTotal () {
      this.eggShowTotal = !this.eggShowTotal
      this.shade = true
    },
    // 获取是否还能捐赠
    async handleIsable () {
      const resp = await donation.queryDonated()
      this.flag = resp.result
      console.log(this.flag, 'xiangq')
    },
    async handledonationInfo () {
      const resp = await donation.donatedInfo()
      this.donatedInfo = resp.result
      this.donatedInfo.amount = this.$web3.fromWei(this.donatedInfo.amount)
      this.donatedInfo.totalCount = this.$web3.fromWei(
        this.donatedInfo.totalCount
      )
      console.log(resp, 'de')
      this.getAllowance()
    },
    // 立即参与
    changeLange (lang) {
      this.lang = lang
    }
  },
  mounted () {
    this.lang = localStorage.getItem('dappLang')
    this.handledonationInfo()
    this.handleIsable()
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  box-sizing: border-box;
  padding: 10px 15px;
  color: #ffffff;
  background: #18191a;
  min-height: 100vh;
  // padding: 0 35px;
  .header-lang {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
    color: #33231a;
    .wattle-box {
      // width: 80px;
      text-align: center;
      line-height: 24px;
      padding: 0 10px;
      // height: 24px;
      background: #18191a;
      // border: 1px solid #ffffff;
      border-radius: 12px;
      font-size: 11px;
      font-weight: 400;
      overflow: hidden;
      color: #ffffff;
    }
    .lang {
      width: 66px;
      height: 24px;
      background: #18191a;
      border: 1px solid #ffffff;
      border-radius: 12px;
      font-size: 11px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 24px;
    }
  }
  .top-img {
    width: 100%;
    height: auto;
    margin-top: 15px;
    border-radius: 20px;
  }
  .title-box{
    text-align: center;
    margin-top: 29px;
    .marketing{
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;

      background: linear-gradient(98deg, #FFAD43 0%, #FF7C6B 14.990234375%, #FD5090 64.990234375%, #662BFC 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .Genesis{
      margin-top: 17px;
      text-align: right;
      font-size: 15px;
      margin-right: 79px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
  .donate-box {
    margin-top: 25px;
    width: 100%;
    // height: 432px;
    background: #252525;
    border-radius: 20px;
    padding: 5px 25px;
    padding-bottom: 0;
    .yinxiao-box {
      padding: 13px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
    .adress-box {
      padding: 13px 0;
    }
    .edu {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.5;
    }
    .money {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .address {
      display: flex;
      align-content: center;
      justify-content: space-between;
      .title {
        width: 65%;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        color: #ffffff;
        text-overflow: ellipsis;
      }
      .copy-img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .btn-div {
    margin-top: 15px;
    width: 100%;
    height: 48px;
    background: #5D3BC5;
    border-radius: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    border: 0;
  }
}
</style>
